import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { CardSection } from '../../../shared/components/card/CardSection';
import { Box, Grid, Typography } from '@mui/material';
import { MagazinePDFPage } from './MagazinePDFPage';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { fetchMagazineEvent } from '../magazine-events';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import {
  MagazineFrequency,
  MagazineStatus,
  MagazineType,
  UpdateMagazineInput,
} from '../magazine-types';
import { SelectDefault } from '../../../shared/components/inputs/SelectDefault';
import { MAGAZINE_FREQUENCY_OPTIONS, MAGAZINE_TYPE_OPTIONS } from '../magazine-constants';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { useAuth } from '../../auth/auth-hook';
import { fetchMagazineAction, updateMagazineAction } from '../magazine-actions';
import { useParams } from 'react-router-dom';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';

const DEFAULT_VALUES = {
  id: '',
  title: '',
  description: '',
  frequency: MagazineFrequency.Daily,
  type: MagazineType.Magazine,
  magazine_category_id: null,
  front_page_id: null,
  published_at: '',
};
export const MagazineDetailTab: React.FC = () => {

  const { magazine, categories } = useEvent(fetchMagazineEvent);
  const [form, setForm] = useState<UpdateMagazineInput>(DEFAULT_VALUES);
  const { tenant } = useAuth();
  const { id } = useParams<{ id: string }>();
  const [callMagazine] = useCallAction(fetchMagazineAction);


  useEffect(() => {
    if (magazine) {
      setForm({
        ...DEFAULT_VALUES,
        id: magazine.id,
        title: magazine.title,
        description: magazine.description,
        frequency: magazine.frequency,
        type: magazine.type,
        published_at: magazine.published_at,
        magazine_category_id: magazine.magazine_category_id,
        front_page_id: magazine.front_page.magazine_page_id,
      });
    }
  }, [magazine]);

  const onChange = (field: string, value: string): void => {
    if (field) {
      setForm({
        ...form,
        [field as keyof UpdateMagazineInput]: value,
      });
    }
  };

  const [callUpdateMagazine, loading] = useCallAction(updateMagazineAction, {
    onCompleted: () => {
      callMagazine(id as string);
      openSnackbarAction(
        'Se ha actualizado correctamente.',
        TypeMessage.SUCCESS,
      );
    },
    onError:()=>{
      openSnackbarAction(
        'Tenemos problemas para actualizar',
        TypeMessage.ERROR,
      );
    }
  });

  const properties = magazine?.magazine_properties;
  const frontPage = magazine?.front_page;
  return (
    <CardSection title={
      <Typography fontWeight='bold' fontSize={20}>Detalles</Typography>
    }>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>

                <Box style={{
                  border: '1px solid #BBBBBB',
                  borderRadius: '16px',
                  boxShadow: '0px 0px 8px 0px #00000014',
                }} height='600' display='flex' justifyContent='center' alignItems='center'>
                  {frontPage ? (
                    <img src={frontPage.url} alt='front_page' height='600' width='100%'
                         style={{ borderRadius: '16px', objectFit: 'fill' }} />
                  ) : (
                    <Typography fontWeight='bold' fontSize={20}>No hay portada asignada</Typography>
                  )}

                </Box>
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputDefault
                  variant='outlined'
                  labelName='TÍTULO'
                  value={form.title}
                  name='title'
                  onChangeData={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputDefault
                  variant='outlined'
                  labelName='DESCRIPCIÓN'
                  value={form.description}
                  name='description'
                  type='textarea'
                  onChangeData={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDefault
                  variant='outlined'
                  labelName='Categoria'
                  value={form.magazine_category_id}
                  options={[...categories.map((category) => ({ value: category.id, label: category.name }))]}
                  name='magazine_category_id'
                  onChangeData={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDefault
                  variant='outlined'
                  labelName='Portada'
                  value={form.front_page_id}
                  options={[...(magazine?.pages || []).map((page) => ({
                    value: page.id,
                    label: `Página ${page.page}`,
                  }))]}
                  name='front_page_id'
                  onChangeData={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDefault
                  variant='outlined'
                  labelName='Tipo de revista'
                  value={form.type}
                  options={MAGAZINE_TYPE_OPTIONS}
                  name='type'
                  onChangeData={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectDefault
                  variant='outlined'
                  labelName='Periodo'
                  value={form.frequency}
                  options={MAGAZINE_FREQUENCY_OPTIONS}
                  name='frequency'
                  onChangeData={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <InputDefault
                  variant='outlined'
                  labelName='FECHA DE PUBLICACIÓN'
                  value={form.published_at}
                  name='published_at'
                  type='date'
                  onChangeData={onChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid xs={4} item>
                <InputDefault
                  variant='outlined'
                  labelName='TAMAÑO'
                  value={properties?.file_size}
                  name='file_size'
                  disabled
                />
              </Grid>
              <Grid xs={4} item>
                <InputDefault
                  variant='outlined'
                  labelName='ARCHIVO'
                  value={properties?.file_name}
                  name='file_name'
                  disabled
                />
              </Grid>
              <Grid xs={4} item>
                <InputDefault
                  variant='outlined'
                  labelName='PÁGINAS'
                  value={properties?.page_count}
                  name='file_size'
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} display='flex' justifyContent='end' alignItems='end'>
            <ButtonDefault
              isLoading={loading}
              onClick={() => callUpdateMagazine(form)}

              startIcon={
                <img
                  src='/icon-update.svg'
                  alt='clip'
                  width={14}
                  height={14}
                />
              }
              style={{
                width: 190,
                height: 40,
                textTransform: 'inherit',
                fontWeight: 700,
                background: tenant?.identityColor ?? 'black',
              }}>
              ACTUALIZAR
            </ButtonDefault>
          </Grid>
        </Grid>
      </Box>
    </CardSection>
  );
};