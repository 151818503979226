import React, { useEffect, useState } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';

import { Typography, Grid, Container, Box, Stack } from '@mui/material';
import { CustomHeader } from '../../shared/components/layout/CustomHeader';
import { useAuth } from '../auth/auth-hook';
import { fetchTenantSubscriptionList } from './tenant-subs-plan-actions';
import { PlansTable } from './components/PlansTable';
import { TenantSubscriptionListEvent } from './tenant-subs-plan-events';
import { TenantSubsPlanTableFilter } from './components/TenantSubsPlanTableFilter';
import { tenantSubscriptionPlansFilterStore } from './tenant-subs-plan-store';

export const TenantSubscriptionPlans: React.FC = () => {
  const { user, tenant } = useAuth();
  const { data, total } = useEvent(TenantSubscriptionListEvent);
  const filter = useStore(tenantSubscriptionPlansFilterStore);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const [callAction, loading] = useCallAction(fetchTenantSubscriptionList, {
    onCompleted: () => {
      // console.log(data);
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  useEffect(() => {
    if (tenant) {
      callAction({
        tenant,
        page: pagination.page,
        pageSize: pagination.pageSize,
        status: filter.status,
        isPremium: filter.isPremium,
      });
    }
  }, [callAction, tenant, pagination, filter]);

  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          background: 'white',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}>
        <Stack
          direction={'row'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ py: '15px' }}>
          <Box>
            <Typography fontSize={{ sx: '16px', md: '20px' }} fontWeight={600}>
              Planes
            </Typography>
          </Box>
          <TenantSubsPlanTableFilter />
        </Stack>

        <CardSection>
          <Grid container spacing={2} xs={12} paddingTop={2}>
            <Grid xs={12}>
              <PlansTable
                data={data}
                loading={loading}
                total={total}
                pagination={pagination}
                hanChangePagination={(newPagination) =>
                  setPagination(newPagination)
                }
              />
            </Grid>
          </Grid>
        </CardSection>
      </Container>
    </>
  );
};
