import moment from 'moment';
import { TableColumnType } from '../../../shared/components/table/table-types';
import { TenantSubscriptionPlan } from '../tenant-subs-plan-types';
import { Container, Link } from '@mui/material';
import { TableDefault } from '../../../shared/components/table/TableDefault';
import { formatNumber } from '../../../shared/utils/number-utils';
import { useNavigate } from 'react-router-dom';

interface PlansTableProps {
  data: TenantSubscriptionPlan[];
  total: number;
  loading: boolean;
  pagination: {
    pageSize: number;
    page: number;
  };
  hanChangePagination: (value: { pageSize: number; page: number }) => void;
}

export const PlansTable: React.FC<PlansTableProps> = ({
  data,
  loading,
  total,
  pagination,
  hanChangePagination,
}) => {
  const navigate = useNavigate();

  const COLUMNS: TableColumnType<TenantSubscriptionPlan>[] = [
    {
      columnName: 'NOMBRE',
      columnValue: null,
      columnComponent: (item) => (
        <Link
          onClick={() => navigate(`/plans/${item.id}`)}
          sx={{
            cursor: 'pointer',
          }}>
          {item.title}
        </Link>
      ),
    },
    {
      columnName: 'PRECIO',
      columnValue: (item) => formatNumber(item.price) || '',
    },
    {
      columnName: 'FRECUENCIA',
      columnValue: (item) => item.frequency || '',
    },
    {
      columnName: 'ESTADO',
      columnValue: (item) => item.status || '',
    },
    {
      columnName: 'PREMIUM',
      columnValue: (item) => (item?.is_premium === true ? 'Sí' : 'No'),
    },
    {
      columnName: 'CREADO',
      columnValue: (item) => moment(item.created_at).format('DD/MM/YYYY') || '',
    },
    {
      columnName: 'Stripe',
      columnValue: (item) => (item.stripe ? '✅' : '❌'),
    },
    {
      columnName: 'Open pay',
      columnValue: (item) => (item.open_pay ? '✅' : '❌'),
    },
    {
      columnName: 'Paypal',
      columnValue: (item) => (item.paypal ? '✅' : '❌'),
    },
  ];

  return (
    <Container
      maxWidth={'lg'}
      style={{
        borderRadius: '16px',
        background: 'white',
        paddingTop: '16px',
        paddingBottom: '16px',
      }}>
      <TableDefault
        items={data}
        count={total}
        columns={COLUMNS}
        page={pagination.page}
        pageSize={pagination.pageSize}
        loading={loading}
        messageLoading={'Cargando usuarios...'}
        handleChangePage={(page) => {
          hanChangePagination({
            ...pagination,
            page: page + 1,
          });
        }}
        showPagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        handleChangeRowPerPage={(e) => {
          hanChangePagination({
            ...pagination,
            pageSize: parseInt(e.target.value, 10),
            page: 1,
          });
        }}
      />
    </Container>
  );
  return <></>;
};
