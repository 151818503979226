import { createEvent } from '@cobuildlab/react-simple-state';
import {
  TenantSubscriptionListType,
  TenantSubscriptionPlan,
  TenantSubsPlanStripeCouponListType,
  TenantSubsPlanStripeCouponType,
} from './tenant-subs-plan-types';

export const TenantSubscriptionListEvent =
  createEvent<TenantSubscriptionListType>({
    initialValue: {
      data: [],
      total: 0,
    },
  });

export const TenantSubscriptionListEventError = createEvent<Error>();

export const fetchTenantSubscriptionEvent =
  createEvent<TenantSubscriptionPlan>();
export const fetchTenantSubscriptionEventError = createEvent<Error>();

export const tenantSubsPlanStripeCouponListEvent =
  createEvent<TenantSubsPlanStripeCouponListType>();
export const tenantSubsPlanStripeCouponListEventError = createEvent<Error>();

export const createTenantSubsPlanStripeCouponEvent =
  createEvent<TenantSubsPlanStripeCouponType>();
export const createTenantSubsPlanStripeCouponEventError = createEvent<Error>();
