import React, { useEffect } from 'react';
import { Accordion, Container, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserEventsQuery } from '../../shared/types/generated';
import { getFullNameEvent } from './tenant-utils';
import moment from 'moment';


export const TenantUserDetailsHistory: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useUserEventsQuery({
    variables: {
      userId: parseInt(id as string),
    },
  });

  const histories = data?.userEvents?.events || [];
  const count = data?.userEvents?.count || 0;

  return (
    <Container maxWidth={'lg'}>
      <Box mb={1}>Cantidad de eventos: {histories.length}</Box>
      {
        histories.map((history) => (
          <Box mb={1}>
            <Accordion>
              <AccordionSummary>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <Box>{getFullNameEvent(history?.name as string)}</Box>
                  <Box>{moment(parseInt(history?.created_at as string)).format('YYYY-MM-DD HH:mm:ss')}</Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box fontWeight='bold'>user: {history?.user_id}</Box>

                {
                  (history?.event_meta || [])?.map((meta) => (
                    <Box>
                      {`${meta?.meta_key} : ${meta?.meta_value}`}
                    </Box>
                  ))
                }

              </AccordionDetails>
            </Accordion>
          </Box>
        ))
      }
    </Container>
  );
};
