import React, { useMemo } from 'react';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import es from 'apexcharts/dist/locales/es.json';
import { CouponTypes } from '../../../tenant-subscription-plans/tenant-subs-plan-types';
import { useStylesCustom } from '../DashboardCardItem';
import { useAuth } from '../../../auth/auth-hook';
import { Loading } from '../../../../shared/components/Loading';
import { formatterCouponType } from '../../dashboard-utils';
import { useBreakPoint } from '../../../../shared/hooks/useBreakPoint';

interface Props {
  loading?: boolean;
  data: {
    coupon_type: CouponTypes;
    coupon_redemptions: {
      id: number;
      created_at: Date | null;
      updated_at: Date | null;
      coupon_id: number;
      user_id: number;
    }[];
    _count: {
      coupon_redemptions: number;
    };
  }[];
}

export const DashboardCouponTypeBar: React.FC<Props> = ({
  loading,
  data = [],
}) => {
  const { isAbove: isAboveMd } = useBreakPoint('md');
  const { isAbove: isAboveSm } = useBreakPoint('sm');
  const classes = useStylesCustom();
  const { tenant } = useAuth();
  const categories = Object.values(CouponTypes);

  const chartData = useMemo(() => {
    const total = categories.map((categorie) => {
      const item = data.find((Group) => categorie === Group.coupon_type);

      if (item) {
        return {
          categorie: categorie,
          total: item?._count.coupon_redemptions ?? 0,
        };
      }
      return {
        categorie: categorie,
        total: 0,
      };
    });

    const items = total.filter((item) => item.total > 0);
    return items;
  }, [categories, data]);

  const DATA: {
    options: ApexOptions;
    series: ApexOptions['series'];
  } = {
    series: [
      {
        name: 'Total',
        data: chartData.map((item) => item.total),
      },
    ],
    options: {
      colors: [tenant?.identityColor],
      chart: {
        id: 'coupon-redemptions-chart',
        height: 300,
        type: 'bar',
        defaultLocale: 'es',
        locales: [es],
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: string) {
          return val.toLocaleString();
        },
        style: {
          fontSize: '12px',
        },
      },
      xaxis: {
        categories: chartData.map((item) => item.categorie),
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        showAlways: true,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          maxWidth: isAboveMd ? 400 : isAboveSm ? 200 : 100,

          formatter: function (val: unknown) {
            if (typeof val === 'string') {
              try {
                return formatterCouponType(val as CouponTypes);
              } catch (error) {
                return val.replaceAll('_', ' ');
              }
            }
            if (typeof val === 'number') {
              return val.toLocaleString();
            }
            return val as string;
          },
        },
      },
    },
  };

  const Component = (): JSX.Element => {
    if (loading) {
      return (
        <Box
          fontSize={32}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={'100%'}>
          <Loading />
        </Box>
      );
    }

    if (chartData.length === 0) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={'100%'}>
          No hay datos disponibles
        </Box>
      );
    }

    return (
      <Chart
        options={DATA.options}
        series={DATA.series}
        type="bar"
        height={'100%'}
      />
    );
  };

  return (
    <Card
      elevation={2}
      style={{
        border: '1px solid #BBBBBB',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 0px #00000014',
      }}>
      <CardHeader
        classes={{
          title: classes.title,
        }}
        className={classes.header}
        title="Cupones usados por tipo"
      />
      <CardContent
        className={classes.content}
        style={{
          height: 100 + (chartData.length > 1 ? chartData.length * 35 : 50),
        }}>
        <Component />
      </CardContent>
    </Card>
  );
};
