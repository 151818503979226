import React from 'react';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  Theme,
  Toolbar,
} from '@mui/material';
import { useStore } from '@cobuildlab/react-simple-state';
import { drawerSidebarEvent } from './layout-events';
import { createStyles, makeStyles } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DRAWER_WIDTH, MIN_DRAWER_WIDTH } from '../../constants';
import { openDrawerSidebar } from './layout-actions';
import { PRIMARY_COLOR } from '../../css/theme';
import { useAuth } from '../../../modules/auth/auth-hook';
import { useBreakPoint } from '../../hooks/useBreakPoint';

interface StylesProps {
  drawerWidth: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: 'transparent',
      color: 'black',
      boxShadow: 'none!important',
      pointerEvents: 'none',
    },
    toolbar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      zIndex: 50,
    },
    countryBadge: {
      backgroundColor: '#d7ffd2',
      color: '#207d32',
      fontWeight: 'bold',
      '& > .MuiChip-label ': {
        textOverflow: 'initial',
      },
    },
    tradingBadge: {
      fontWeight: 'bold',
      '& > .MuiChip-label ': {
        textOverflow: 'initial',
        overflow: 'initial',
      },
    },
    appBarShift: {
      /**
       *
       * @param {object} props - Styles Props.
       * @param {number} props.drawerWidth - DrawerWidth.
       * @returns {string} - Width Style.
       */
      /**
       *
       * @param {object} props - Styles Props.
       * @param {boolean} props.drawerWidth - Drawer Width.
       * @returns {number} - Drawer Width.
       */
      marginLeft: (props) => props.drawerWidth,
      backgroundColor: '#F7F7F7',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    text: { color: PRIMARY_COLOR },
  }),
);

export const OnlyArrow: React.FC = () => {
  const { isBelow } = useBreakPoint('md');
  const { isOpen } = useStore(drawerSidebarEvent);
  const { tenant } = useAuth();
  const classes = useStyles({
    drawerWidth: 250,
  });


  return (
    <AppBar
      position='fixed'
      className={classes.appBar}
      style={
        !isBelow
          ? {
            width: `calc(100% - ${isOpen ? DRAWER_WIDTH : MIN_DRAWER_WIDTH}px)`,
            left: isOpen ? DRAWER_WIDTH - 22 : MIN_DRAWER_WIDTH - 22,
            zIndex: 50,
            top: 50,
          }
          : {
            width: `calc(100% - ${isOpen ? DRAWER_WIDTH : MIN_DRAWER_WIDTH}px)`,
            left: isOpen ? DRAWER_WIDTH - 80 : MIN_DRAWER_WIDTH - 80,
            zIndex: 50,
            top: 30,
          }
      }
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          style={{
            padding: '2px',
            pointerEvents: 'auto',
            background: !isOpen
              ? tenant?.identityColor ?? '#323232d9'
              : 'white',
            color: !isOpen ? 'white' : 'black',
            zIndex: 50,
            border: '1px solid var(--Color-Border, #E2E8F0)',
          }}
          onClick={() => openDrawerSidebar(!isOpen)}
        >
          {!isOpen ? (
            <ChevronRightIcon color='inherit' />
          ) : (
            <ChevronLeftIcon color='inherit' />
          )}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
