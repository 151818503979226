import React, { useEffect, useState } from 'react';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import { useAuth } from '../../../auth/auth-hook';
import {
  changeDashboardDateFilterStore,
  fetchCouponRedemptionsListEvent,
} from '../../dashboard-events';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { fetchCouponRedemptionsList } from '../../dashboard-actions';
import moment from 'moment';
import { useStylesCustom } from './../DashboardCardItem';
import { CouponRedemptionType } from '../../../tenant-subscription-plans/coupons/coupon-types';

export const CouponRedemptionsTable: React.FC = () => {
  const { data, total } = useEvent(fetchCouponRedemptionsListEvent);
  const { from, to } = useStore(changeDashboardDateFilterStore);
  const [callFetchCoupons, loading] = useCallAction(fetchCouponRedemptionsList);

  const { tenant } = useAuth();

  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  useEffect(() => {
    if (tenant) {
      callFetchCoupons({
        tenant,
        from,
        to,
        page: pagination.page,
        pageSize: pagination.pageSize,
      });
    }
  }, [
    callFetchCoupons,
    from,
    pagination.page,
    pagination.pageSize,
    tenant,
    to,
  ]);

  const COLUMNS: TableColumnType<CouponRedemptionType>[] = [
    {
      columnName: <Typography color={'#BBBBBB'}>FECHA DE USO</Typography>,
      columnValue: (item) =>
        item?.created_at
          ? moment(item.created_at).format('DD-MM-YYYY HH:mm')
          : '',
    },
    {
      columnName: <Typography color={'#BBBBBB'}>CUPON</Typography>,
      columnValue: (item) => item?.coupon?.coupon ?? '',
    },
    {
      columnName: <Typography color={'#BBBBBB'}>EMAIL</Typography>,
      columnValue: (item) => item?.user?.email ?? '',
    },
    {
      columnName: <Typography color={'#BBBBBB'}>PLAN ASOCIADO</Typography>,
      columnValue: (item) => item?.plan.title ?? '',
    },
  ];
  const classes = useStylesCustom();
  return (
    <>
      <Box
        marginTop={1}
        marginBottom={2}
        sx={{
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        alignItems={'center'}>
        <Typography
          component={'div'}
          sx={{ marginBottom: { xs: '10px', md: '0px' } }}
          fontSize={20}
          fontWeight={700}>
          Cupones usados
        </Typography>
      </Box>
      <Card
        elevation={2}
        style={{
          border: '1px solid #BBBBBB',
          padding: '16px',
          borderRadius: '16px',
          boxShadow: '0px 0px 8px 0px #00000014',
        }}>
        <CardContent className={classes.content}>
          <TableDefaultSticky
            count={total}
            items={data}
            columns={COLUMNS}
            page={pagination.page}
            pageSize={pagination.pageSize}
            loading={loading}
            height={'auto'}
            messageLoading={'Cargando cupones...'}
            handleChangePage={(page) => {
              if (tenant) {
                setPagination({
                  ...pagination,
                  page: page + 1,
                });
              }
            }}
            rowsPerPageOptions={[10, 20, 50, 100]}
            handleChangeRowPerPage={(e) => {
              setPagination({
                ...pagination,
                pageSize: parseInt(e.target.value, 10),
                page: 1,
              });
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
