import React from 'react';
import { DashboardCardItem } from '../DashboardCardItem';

interface Props {
  title: string;
  loading: boolean;
  total: number;
}
export const DashboardCouponsTotalItem: React.FC<Props> = ({
  title,
  loading = false,
  total = 0,
}) => {
  return <DashboardCardItem title={title} content={total} loading={loading} />;
};
