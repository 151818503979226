import { useStore } from '@cobuildlab/react-simple-state';
import { TenantSubsPlanDetailsTabs } from './tenant-subs-plan-types';
import { tenantSubscriptionPlansSteps } from './tenant-subs-plan-store';

interface DetailsPlanStepsReturn {
  step: TenantSubsPlanDetailsTabs;
  handleChangeStep: (newStep: TenantSubsPlanDetailsTabs) => void;
}

export const useDetailsPlanSteps = (): DetailsPlanStepsReturn => {
  const step = useStore(tenantSubscriptionPlansSteps);
  const handleChangeStep = (newStep: TenantSubsPlanDetailsTabs): void => {
    tenantSubscriptionPlansSteps.dispatch(newStep);
  };

  return {
    step,
    handleChangeStep,
  };
};
