import { createStore } from '@cobuildlab/react-simple-state';
import { TenantSubsPlanDetailsTabs } from './tenant-subs-plan-types';

export const tenantSubscriptionPlansFilterStore = createStore<{
  status: string;
  isPremium: string;
}>({
  initialValue: {
    status: 'all',
    isPremium: 'all',
  },
});

export const tenantSubscriptionPlansSteps =
  createStore<TenantSubsPlanDetailsTabs>({
    initialValue: TenantSubsPlanDetailsTabs.DETAILS,
  });
