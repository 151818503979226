import moment from 'moment';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import { TenantSubsPlanStripeCouponType } from '../../tenant-subs-plan-types';
import { Box, Container, Link, Typography } from '@mui/material';
import { TableDefault } from '../../../../shared/components/table/TableDefault';
import { formatNumber } from '../../../../shared/utils/number-utils';
import { openSnackbarAction } from '../../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../../shared/components/snackbar/snackbar-types';
import { useMemo, useState } from 'react';
import { ShareCouponModal } from './ShareCouponModal';
import { useCallAction } from '@cobuildlab/react-simple-state';
import {
  createSharedCoupon,
  enableOrDisableStripeCoupon,
} from '../coupon-actions';
import { useAuth } from '../../../auth/auth-hook';

interface StripeCouponsTableProps {
  data: TenantSubsPlanStripeCouponType[];
  total: number;
  loading: boolean;
  pagination: {
    pageSize: number;
    page: number;
  };
  hanChangePagination: (value: { pageSize: number; page: number }) => void;
  isAnnualPlan?: boolean;
  refetch?: () => void;
}

export const StripeCouponsTable: React.FC<StripeCouponsTableProps> = ({
  data,
  loading,
  total,
  pagination,
  hanChangePagination,
  isAnnualPlan = false,
  refetch,
}) => {
  const { tenant } = useAuth();
  const [isReferral, setIsReferral] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [currentCoupon, setCurrentCoupon] =
    useState<TenantSubsPlanStripeCouponType | null>(null);

  const [callSharedCoupon, loadingSharedCoupon] = useCallAction(
    createSharedCoupon,
    {
      onCompleted: () => {
        setOpenShareModal(false);
        setCurrentCoupon(null);
        openSnackbarAction('Cupon compartido', TypeMessage.SUCCESS);
      },
      onError: (error) => {
        openSnackbarAction(error.message, TypeMessage.ERROR);
      },
    },
  );

  const [callToggleCoupon] = useCallAction(enableOrDisableStripeCoupon, {
    onCompleted: (event) => {
      const state = event.status === 'ACTIVE' ? 'activado' : 'desactivado';
      openSnackbarAction(`Cupon ${state} con exito.`, TypeMessage.SUCCESS);
      if (refetch) {
        refetch();
      }
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  const copyToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackbarAction('Cupon copiado', TypeMessage.SUCCESS);
    });
  };

  const COLUMNS = useMemo(() => {
    const columns: TableColumnType<TenantSubsPlanStripeCouponType>[] = [
      {
        columnName: 'CUPON',
        columnValue: null,
        columnComponent: (item) => (
          <Link
            underline="hover"
            onClick={() => copyToClipboard(item.coupon)}
            sx={{
              fontWeight: 700,
              cursor: 'pointer',
            }}>
            {item.coupon}
          </Link>
        ),
      },
      {
        columnName: 'CREADO',
        columnValue: (item) =>
          moment(item.created_at).format('DD/MM/YYYY') || '',
      },
      {
        columnName: 'ESTADO',
        columnValue: (item) => item.status || '',
      },
      {
        columnName: 'MONTO',
        columnValue: (item) => formatNumber(item?.discount_amount ?? 0),
      },
      {
        columnName: 'DESCUENTO',
        columnValue: (item) =>
          item?.discount_percent ? `${item.discount_percent}%` : '0%',
      },
      {
        columnName: 'USO MAXIMO',
        columnValue: (item) => item?.max_redemptions ?? 0,
      },
      {
        columnName: 'LIMITE',
        columnValue: (item) =>
          item?.end_date ? moment(item.end_date).format('DD/MM/YYYY') : '',
      },
    ];

    if (!isAnnualPlan) {
      columns.push({
        columnName: 'MESES DE DURACION',
        columnValue: (item) =>
          item?.duration_in_months && item.duration_in_months > 1
            ? item.duration_in_months
            : '',
      });
    }

    return columns;
  }, [isAnnualPlan]);

  return (
    <Container
      maxWidth={'lg'}
      style={{
        borderRadius: '16px',
        background: 'white',
        paddingTop: '16px',
        paddingBottom: '16px',
      }}>
      <ShareCouponModal
        isReferral={isReferral}
        coupon={currentCoupon?.coupon ?? ''}
        open={openShareModal && !!currentCoupon}
        onClose={() => {
          setCurrentCoupon(null);
          setOpenShareModal(false);
        }}
        onSubmit={(emails) => {
          if (!currentCoupon || !tenant) return;

          callSharedCoupon({
            isReferral,
            emails,
            tenant,
            couponId: currentCoupon?.id as number,
          });
        }}
        loading={loadingSharedCoupon}
      />
      <TableDefault
        items={data}
        count={total}
        columns={COLUMNS}
        page={pagination.page}
        pageSize={pagination.pageSize}
        loading={loading}
        messageLoading={'Cargando usuarios...'}
        handleChangePage={(page) => {
          hanChangePagination({
            ...pagination,
            page: page + 1,
          });
        }}
        showPagination={data.length > 0}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        handleChangeRowPerPage={(e) => {
          hanChangePagination({
            ...pagination,
            pageSize: parseInt(e.target.value, 10),
            page: 1,
          });
        }}
        actions={[
          {
            actionName: 'Compartir',
            onClick: (item) => {
              setIsReferral(false);
              setOpenShareModal(true);
              setCurrentCoupon(item);
            },
          },
          {
            actionName: 'Asignar para compartir',
            onClick: (item) => {
              setIsReferral(true);
              setOpenShareModal(true);
              setCurrentCoupon(item);
            },
          },
          {
            actionName: (item) =>
              item.status === 'ACTIVE' ? 'Desactivar' : 'Activar',
            onClick: (item) => {
              if (!tenant) return;
              callToggleCoupon({ tenant, couponId: item.id });
            },
          },
        ]}
      />
      {data.length === 0 && !loading && (
        <Box pt={3}>
          <Typography sx={{ textAlign: 'center' }} fontSize={'16px'}>
            No hay cupones disponibles
          </Typography>
        </Box>
      )}
    </Container>
  );
};
