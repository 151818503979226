import { Box, Divider, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  TenantSubscriptionPlan,
  TenantSubsPlanStripeCouponListType,
} from '../tenant-subs-plan-types';
import { useCallAction } from '@cobuildlab/react-simple-state';
import {
  createTenantSubsPlanStripeCoupon,
  fetchtenantSubsPlanStripeCouponList,
} from '../tenant-subs-plan-actions';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { StripeCouponsTable } from './components/StripeCouponsTable';
import { useAuth } from '../../auth/auth-hook';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { CreateCouponModal } from './components/CreateCouponModal';

interface StripeCouponsViewProps {
  id: string;
  tenantSubPlanDetails: TenantSubscriptionPlan | null;
}

export const CouponsView: FC<StripeCouponsViewProps> = ({
  id,
  tenantSubPlanDetails,
}) => {
  const [open, setOpen] = useState(false);
  const { tenant } = useAuth();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [loading, setLoading] = useState(true);
  const [couponList, setCouponList] =
    useState<TenantSubsPlanStripeCouponListType>();

  const [call] = useCallAction(fetchtenantSubsPlanStripeCouponList, {
    onCompleted: (data) => {
      setCouponList(data);
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  const refetch = useCallback(() => {
    if (!tenant || !tenantSubPlanDetails?.stripe) return;

    setLoading(true);
    call({
      tenant,
      planId: id as string,
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
  }, [tenant, id, pagination, call, tenantSubPlanDetails]);

  const [callCreate, loadingCreate] = useCallAction(
    createTenantSubsPlanStripeCoupon,
    {
      onCompleted: () => {
        openSnackbarAction('Cupón creado con éxito', TypeMessage.SUCCESS);
        setOpen(false);
        refetch();
      },
      onError: (error) => {
        openSnackbarAction(error.message, TypeMessage.ERROR);
      },
    },
  );

  useEffect(() => {
    if (!tenant || !tenantSubPlanDetails?.stripe) return;

    setLoading(true);
    call({
      tenant,
      planId: id as string,
      page: pagination.page,
      pageSize: pagination.pageSize,
    });
  }, [call, tenant, id, pagination, tenantSubPlanDetails]);

  return (
    <>
      <CreateCouponModal
        open={open}
        handleClose={() => setOpen(false)}
        buttonColor={tenant?.identityColor}
        loading={loadingCreate}
        isAnnualPlan={tenantSubPlanDetails?.frequency === 'YEAR'}
        onSubmit={(data) => {
          if (!tenant) return;
          callCreate({ tenant, planId: id as string, ...data });
        }}
      />

      <Stack direction={'column'} spacing={2} divider={<Divider />}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{ textAlign: { xs: 'center', md: 'start' } }}
            fontSize={'18px'}
            fontWeight={700}>
            Cupones de stripe
          </Typography>
          {tenantSubPlanDetails?.stripe && (
            <ButtonDefault
              onClick={() => setOpen(true)}
              style={{
                width: 100,
                height: 40,
                textTransform: 'inherit',
                fontWeight: 700,
                background: tenant?.identityColor ?? undefined,
              }}>
              Crear
            </ButtonDefault>
          )}
        </Box>
        {!tenantSubPlanDetails?.stripe ? (
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ textAlign: 'center' }}>
            No se ha configurado la integración con Stripe
          </Typography>
        ) : (
          <Box>
            <StripeCouponsTable
              data={couponList?.data || []}
              loading={loading}
              total={couponList?.total || 0}
              pagination={pagination}
              refetch={refetch}
              isAnnualPlan={tenantSubPlanDetails?.frequency === 'YEAR'}
              hanChangePagination={(value) => {
                setLoading(true);
                setPagination(value);
              }}
            />
          </Box>
        )}
      </Stack>
    </>
  );
};
