import React, { ReactNode } from 'react';
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ButtonDefault } from '../ButtonDefault';
import { useAuth } from '../../../modules/auth/auth-hook';

type DialogDefaultProps = {
  title: string;
  children: ReactNode;
  handleSuccess?: () => void;
  isOpen: boolean;
  handleClose?: () => void;
  isLoading?: boolean;
  showActions?: boolean;
  maxWidth?: Breakpoint;
  disabledAction?: boolean;
};

export const DialogDefault: React.FC<DialogDefaultProps> = ({
  title,
  children,
  handleSuccess,
  handleClose,
  isOpen,
  isLoading = false,
  showActions = false,
  maxWidth,
  disabledAction,
}) => {
  const { tenant } = useAuth();
  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose && handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth ?? 'lg'}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ width: 500 }}>{children}</DialogContent>
      {showActions && (
        <DialogActions>
          <ButtonDefault
            color="primary"
            variant="outlined"
            onClick={() => handleClose && handleClose()}
            style={{ width: 136 }}>
            Cancelar
          </ButtonDefault>
          <ButtonDefault
            onClick={() => handleSuccess && handleSuccess()}
            isLoading={isLoading}
            disabled={disabledAction}
            sx={{
              backgroundColor: tenant ? tenant.identityColor : undefined,
            }}>
            Confirmar
          </ButtonDefault>
        </DialogActions>
      )}
    </Dialog>
  );
};
