import React, { ReactElement } from 'react';
import {
  Box,
  Tab,
  tabClasses,
  Tabs,
  Stack,
  IconButton,
  Container,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import { CustomHeader } from '../../../shared/components/layout/CustomHeader';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDetailsPlanSteps } from '../tenant-subs-plan-hooks';
import { TenantSubsPlanDetailsTabs } from '../tenant-subs-plan-types';

const ROUTES = [
  {
    step: TenantSubsPlanDetailsTabs.DETAILS,
    name: 'Detalles del plan',
  },
  {
    step: TenantSubsPlanDetailsTabs.CUPONES,
    name: 'Cupones',
  },
];

type SettingLayoutProps = {
  children: ReactElement;
};

export const TenantSubsPlanDetailsLayout: React.FC<SettingLayoutProps> = ({
  children,
}) => {
  const [currentTab, setCurrentTab] = React.useState(1);
  const navigate = useNavigate();
  const { user, tenant } = useAuth();
  const { handleChangeStep } = useDetailsPlanSteps();

  const TabItem = styled(Tab)({
    position: 'relative',
    borderRadius: '30px !important',
    textAlign: 'center',
    transition: 'all .5s',
    padding: '8px 10px !important',
    color: '#BBBBBB',
    height: '35px !important',
    margin: '6px 2px',
    float: 'none',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: '700',
    minHeight: '38px',
    textTransform: 'capitalize',
    [`&.${tabClasses.selected}, &:hover`]: {
      color: tenant?.identityColor ?? 'black',
      backgroundColor: tenant?.identityColor + '20' ?? 'black',
    },
  });

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    setCurrentTab(newValue ?? 0);
  };

  return (
    <>
      <CustomHeader
        title={`Bienvenido de vuelta, ${user?.name}`}
        subtitle={
          'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
        }
      />
      <Container
        maxWidth={'lg'}
        style={{
          borderRadius: '16px',
          padding: '16px 16px',
          background: 'white',
        }}
      >
        <Stack direction="column" width={'100%'} spacing={2}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              paddingBottom: 1,
            }}
          >
            <Tabs
              sx={{
                padding: { sm: '0px 10px !important' },

                ['& .MuiTabs-indicator']: {
                  display: 'none !important',
                },
              }}
              value={currentTab}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <IconButton
                onClick={() => navigate('/plans')}
                sx={{ width: '30px', height: '30px', my: '10px' }}
              >
                <ArrowBackIosIcon sx={{ fontSize: 14 }} />
              </IconButton>

              {ROUTES.map((route, key) => (
                <TabItem
                  key={key}
                  label={route.name}
                  onClick={() => handleChangeStep(route.step)}
                />
              ))}
            </Tabs>
          </Box>
          <Paper variant="outlined">
            <Box width="100%" py={'16px'}>
              <Box>{children}</Box>
            </Box>
          </Paper>
        </Stack>
      </Container>
    </>
  );
};
