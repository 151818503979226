import React from 'react';
import {
  Box,
  Grid,
  Tabs,
  Tab,
  tabClasses,
  Container,
} from '@mui/material';
import { DashboardGeneralTab } from './components/DashboardGeneralTab';
import { DashboardGoogleTab } from './components/DashboardGoogleTab';
import { useAuth } from '../auth/auth-hook';
import { RoleAuthorizationAdmin } from '../rbac/RoleAuthorization';
import { RBACPermissions } from '../rbac/rbac-types';
import { DashboardSubscriptionTab } from './components/subscriptions/DashboardSubscriptionTab';
import { DashboardWallCrashesTab } from './components/DashboardWallCrashesTab';
import { styled } from '@mui/styles';
import { CustomHeader } from '../../shared/components/layout/CustomHeader';
import { DashboardCouponsTab } from './components/coupons/DashboardCouponsTab';

export const DashboardTenant: React.FC = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const { user, tenant } = useAuth();
  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    // const currentTime = momentTimezone()
    //   .tz(tenant?.timezone as string)
    //   .startOf('day')
    //   .format();
    //
    // if (newValue === 1)
    //   changeGoogleDateFilterStoreAction(
    //     moment(currentTime).toISOString(),
    //     moment().toISOString(),
    //     DateFilter.TODAY,
    //   );
    // if (newValue === 0) {
    //   changeDashboardDateFilterStoreAction(
    //     moment(currentTime).toISOString(),
    //     moment().toISOString(),
    //     DateFilter.TODAY,
    //   );
    //   fetchUsersCountStoreAction(true, []);
    // }

    setCurrentTab(newValue ?? 0);
  };
  const TabItem = styled(Tab)({
    position: 'relative',
    borderRadius: '30px !important',
    textAlign: 'center',
    transition: 'all .5s',
    padding: '8px 10px !important',
    color: '#BBBBBB',
    height: '35px !important',
    margin: '6px 2px',
    float: 'none',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: '700',
    minHeight: '38px',
    textTransform: 'capitalize',
    [`&.${tabClasses.selected}, &:hover`]: {
      color: tenant?.identityColor ?? 'black',
      backgroundColor: tenant?.identityColor + '20' ?? 'black',
    },
  });
  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        spacing={1}
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            px: 2,
          },
        })}
      >
        <CustomHeader
          title={`Bienvenido de vuelta, ${user?.name}`}
          subtitle={
            'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
          }
        />

        <Container
          maxWidth={'lg'}
          style={{ borderRadius: '16px', background: 'white' }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              sx={{
                padding: { sm: '0px 10px !important' },

                ['& .MuiTabs-indicator']: {
                  display: 'none !important',
                },
              }}
              value={currentTab}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <TabItem label="General" />
              <TabItem label="Google" />
              <TabItem label="Suscripciones" />
              <TabItem label="Choques" />
              <TabItem label="Cupones" />
            </Tabs>
          </Box>
          <Box sx={{ padding: { xs: '0px', sm: '16px' } }}>
            {currentTab === 0 && <DashboardGeneralTab />}

            {currentTab === 1 && (
              <RoleAuthorizationAdmin
                render={() => <DashboardGoogleTab />}
                permission={RBACPermissions.TENANT_DASHBOARD_GOOGLE}
              />
            )}

            {currentTab === 2 && (
              <RoleAuthorizationAdmin
                render={() => <DashboardSubscriptionTab />}
                permission={RBACPermissions.TENANT_DASHBOARD_SUBSCRIPTION}
              />
            )}

            {currentTab === 3 && (
              <RoleAuthorizationAdmin
                render={() => <DashboardWallCrashesTab />}
                permission={RBACPermissions.TENANT_DASHBOARD_WALL_CRASHES}
              />
            )}

            {currentTab === 4 && (
              <RoleAuthorizationAdmin
                render={() => <DashboardCouponsTab />}
                permission={RBACPermissions.TENANT_DASHBOARD_WALL_CRASHES}
              />
            )}
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};
