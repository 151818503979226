import { FC, useEffect, useState } from 'react';
import { DialogDefault } from '../../../../shared/components/dialog/DialogDefault';
import { Box, Chip, IconButton, InputAdornment, Stack } from '@mui/material';
import { InputDefault } from '../../../../shared/components/inputs/InputDefault';
import AddIcon from '@mui/icons-material/Add';

interface ShareCouponModalProps {
  isReferral: boolean;
  coupon: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (emails: string[]) => void;
  loading: boolean;
}

export const ShareCouponModal: FC<ShareCouponModalProps> = ({
  isReferral,
  coupon,
  open,
  onClose,
  onSubmit,
  loading,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [emails, setEmails] = useState<string[]>([]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  const handleAddEmail = (): void => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(inputValue);
    const isRepeated = emails.includes(inputValue);

    if (isValidEmail && !isRepeated) {
      setEmails([...emails, inputValue]);
      setInputValue('');
    }
  };

  const handleDelete = (email: string): void => {
    setEmails(emails.filter((item) => item !== email));
  };

  const handleSubmit = (): void => {
    if (emails.length > 0) {
      onSubmit(emails);
    }
  };

  useEffect(() => {
    if (!open) {
      setInputValue('');
      setEmails([]);
    }
  }, [open]);

  const ActiveEmails = (): JSX.Element => (
    <Box>
      {emails.map((item, index) => (
        <Chip
          key={index}
          label={item}
          variant="filled"
          onDelete={() => handleDelete(item)}
        />
      ))}
    </Box>
  );

  return (
    <DialogDefault
      isOpen={open}
      handleClose={onClose}
      title={
        isReferral
          ? 'Asignar usuario para compartir cupón'
          : 'Compartir cupón ' + coupon
      }
      showActions
      isLoading={loading}
      maxWidth="sm"
      disabledAction={emails.length === 0}
      handleSuccess={handleSubmit}>
      <Stack direction="column" spacing={2}>
        <InputDefault
          variant="outlined"
          labelName={isReferral ? 'Email del usuario ya registrado:' : 'Email:'}
          value={inputValue}
          onChange={handleEmailChange}
          fullWidth
          endIcon={
            <InputAdornment position="end">
              <IconButton onClick={handleAddEmail} edge="end">
                <AddIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <ActiveEmails />
      </Stack>
    </DialogDefault>
  );
};
