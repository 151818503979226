import { createAction } from '@cobuildlab/react-simple-state';
import {
  createSharedCouponEvent,
  createSharedCouponEventError,
  enableOrDisableStripeCouponEvent,
  enableOrDisableStripeCouponEventError,
} from './coupon-events';
import { Tenant } from '../../../shared/types/generated';
import axios from 'axios';
import { TenantSubsPlanStripeCouponType } from '../tenant-subs-plan-types';

export const createSharedCoupon = createAction(
  createSharedCouponEvent,
  createSharedCouponEventError,
  async (data: {
    tenant: Tenant;
    couponId: number;
    emails: string[];
    isReferral: boolean;
  }) => {
    const { tenant, couponId, emails, isReferral } = data;

    const urlShared = `${tenant.webhook}/api/stripe-coupon/${couponId}/shared-coupon`;
    const urlReferral = `${tenant.webhook}/api/stripe-coupon/${couponId}/assign-referral-coupon`;
    const url = isReferral ? urlReferral : urlShared;

    try {
      await axios.post(url, { emails }, { timeout: 60000 });
    } catch (error: any) {
      console.log('error', error);
      const msg =
        error?.response?.data?.message ?? 'Error al compartir el cupón';
      throw new Error(msg);
    }
  },
);

export const enableOrDisableStripeCoupon = createAction(
  enableOrDisableStripeCouponEvent,
  enableOrDisableStripeCouponEventError,
  async (data: { tenant: Tenant; couponId: number }) => {
    const { tenant, couponId } = data;

    const url = `${tenant.webhook}/api/stripe-coupon/${couponId}/toggle`;

    try {
      const response = await axios.post<TenantSubsPlanStripeCouponType>(url);

      return response.data;
    } catch (error: any) {
      console.log('error', error);
      const msg =
        error?.response?.data?.message ?? 'Error al actualizar el cupón';
      throw new Error(msg);
    }
  },
);
