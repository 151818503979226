import React from 'react';
import { Route, Routes as RoutesComponent } from 'react-router-dom';
import { Auth } from './modules/auth/Auth';
import { DashboardAdmin } from './modules/dashboard/DashboardAdmin';
import { Layout } from './shared/components/layout/Layout';
import { Session } from './modules/session/Session';
import { Tenants } from './modules/tenant/Tenants';
import { TenantDetails } from './modules/tenant/TenantDetails';
import { TenantLayout } from './modules/tenant/components/TanantLayout';
import { TenantPlans } from './modules/tenant/TenantPlans';
import { TenantUserInvitations } from './modules/tenant/TenantUserInvitations';

import { RoleAuthorizationAdmin } from './modules/rbac/RoleAuthorization';
import { TenantInvitations } from './modules/tenant/TenantInvitations';
import { useDefaultRedirect } from './modules/session/session-hooks';
import { useAuth } from './modules/auth/auth-hook';
import { Loading } from './shared/components/Loading';
import { SettingAccount } from './modules/setting/SettingAccount';
import { SettingPlans } from './modules/setting/SettingPlans';
import { Logout } from './modules/auth/Logout';
import { RBACPermissions } from './modules/rbac/rbac-types';
import { DashboardTenant } from './modules/dashboard/DashboardTenant';
import { SettingTenant } from './modules/setting/SettingTenant';
import { SettingInvitations } from './modules/setting/SettingInvitations';
import { SettingUsers } from './modules/setting/SettingUsers';
import { TenantUsers } from './modules/tenant/TenantUsers';
import { Comments } from './modules/comment/Comments';
import { Comment } from './modules/comment/Comment';
import { SettingLayoutNew } from './modules/setting/components/SettingLayoutNew';
import { MagazineList } from './modules/magazine/MagazineList';

import { TenantUserDetails } from './modules/tenant/TenantUserDetails';
import { TenantUserDetailsLayout } from './modules/tenant/components/TenantUserDetailsLayout';
import { TenantUserDetailsHistory } from './modules/tenant/TenantUserDetailsHistory';
import { SettingLayoutTable } from './shared/components/layout/SettingLayoutTable';
import { MagazineLayout } from './modules/magazine/components/MagazineLayout';
import { Magazine } from './modules/magazine/Magazine';
import { CustomSizes } from './shared/components/loading-types';
import { TenantSubscriptionPlans } from './modules/tenant-subscription-plans/TenantSubscriptionPlans';
import { TenantSubsPlanDetails } from './modules/tenant-subscription-plans/TenantSubsPlanDetails';
import { TenantSubsPlanDetailsLayout } from './modules/tenant-subscription-plans/components/TenantSubsPlanDetailsLayout';

export const Routes: React.FC = () => {
  const { loading } = useAuth();

  useDefaultRedirect();

  return loading ? (
    <Loading customSize={CustomSizes.lARGE} />
  ) : (
    <RoutesComponent>
      <Route path="/auth" element={<Auth />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="*"
        element={
          <Session>
            <TenantInvitations />
            <Layout>
              <RoutesComponent>
                <Route
                  path="/home"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => <DashboardAdmin />}
                      permission={RBACPermissions.DASHBOARD_ADMINISTRATOR}
                    />
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => <DashboardTenant />}
                      permission={RBACPermissions.DASHBOARD_TENANT}
                    />
                  }
                />
                <Route
                  path="/global-users"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => <TenantUsers />}
                      permission={RBACPermissions.SETTING_GLOBAL_USERS}
                    />
                  }
                />
                <Route
                  path="/global-users/:id"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <TenantUserDetailsLayout>
                          <TenantUserDetails />
                        </TenantUserDetailsLayout>
                      )}
                      permission={RBACPermissions.SETTING_GLOBAL_USERS}
                    />
                  }
                />
                <Route
                  path="/global-users/:id/history"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <TenantUserDetailsLayout>
                          <TenantUserDetailsHistory />
                        </TenantUserDetailsLayout>
                      )}
                      permission={RBACPermissions.SETTING_GLOBAL_USERS}
                    />
                  }
                />

                <Route
                  path="/plans"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => <TenantSubscriptionPlans />}
                      permission={RBACPermissions.TENANT_PLANS}
                    />
                  }
                />
                <Route
                  path="/plans/:id"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <TenantSubsPlanDetailsLayout>
                          <TenantSubsPlanDetails />
                        </TenantSubsPlanDetailsLayout>
                      )}
                      permission={RBACPermissions.SETTING_GLOBAL_USERS}
                    />
                  }
                />

                <Route
                  path="/comments"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => <Comments />}
                      permission={RBACPermissions.TENANT_COMMENTS}
                    />
                  }
                />
                <Route
                  path="/comments/:id"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => <Comment />}
                      permission={RBACPermissions.TENANT_COMMENTS}
                    />
                  }
                />
                <Route
                  path="/magazine"
                  element={
                    <MagazineLayout>
                      <RoleAuthorizationAdmin
                        render={() => <MagazineList />}
                        permission={RBACPermissions.TENANT_MAGAZINES}
                      />
                    </MagazineLayout>
                  }
                />
                <Route
                  path="/magazine/details/:id"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => <Magazine />}
                      permission={RBACPermissions.TENANT_MAGAZINES}
                    />
                  }
                />
                <Route
                  path="/magazine/categories"
                  element={
                    <MagazineLayout>
                      <RoleAuthorizationAdmin
                        render={() => <MagazineList />}
                        permission={RBACPermissions.TENANT_MAGAZINES}
                      />
                    </MagazineLayout>
                  }
                />
                <Route
                  path="/tenants"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <SettingLayoutTable>
                          <Tenants />
                        </SettingLayoutTable>
                      )}
                      permission={RBACPermissions.TENANTS}
                    />
                  }
                />
                <Route
                  path="/tenant/:id"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <TenantLayout>
                          <TenantDetails />
                        </TenantLayout>
                      )}
                      permission={RBACPermissions.TENANTS}
                    />
                  }
                />

                <Route
                  path="/tenant/:id/plans"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <TenantLayout>
                          <TenantPlans />
                        </TenantLayout>
                      )}
                      permission={RBACPermissions.TENANTS}
                    />
                  }
                />

                <Route
                  path="/tenant/:id/invitations"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <TenantLayout>
                          <TenantUserInvitations />
                        </TenantLayout>
                      )}
                      permission={RBACPermissions.TENANTS}
                    />
                  }
                />
                <Route
                  path="/tenant/:id/users"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <TenantLayout>
                          <TenantUsers />
                        </TenantLayout>
                      )}
                      permission={RBACPermissions.TENANTS}
                    />
                  }
                />
                <Route
                  path="/settings/account"
                  element={
                    <SettingLayoutNew>
                      <SettingAccount />
                    </SettingLayoutNew>
                  }
                />

                <Route
                  path="/settings/plans"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <SettingLayoutNew>
                          <SettingPlans />
                        </SettingLayoutNew>
                      )}
                      permission={RBACPermissions.SETTING_PLANS}
                    />
                  }
                />
                <Route
                  path="/settings/plans"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <SettingLayoutNew>
                          <SettingPlans />
                        </SettingLayoutNew>
                      )}
                      permission={RBACPermissions.SETTING_PLANS}
                    />
                  }
                />

                <Route
                  path="/settings/tenant"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <SettingLayoutNew>
                          <SettingTenant />
                        </SettingLayoutNew>
                      )}
                      permission={RBACPermissions.SETTING_TENANT}
                    />
                  }
                />
                <Route
                  path="/settings/tenant-users"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <SettingLayoutNew>
                          <SettingUsers />
                        </SettingLayoutNew>
                      )}
                      permission={RBACPermissions.TENANT_USERS}
                    />
                  }
                />

                <Route
                  path="/settings/tenant-invitations"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <SettingLayoutNew>
                          <TenantUserInvitations />
                        </SettingLayoutNew>
                      )}
                      permission={RBACPermissions.SETTING_TENANT_INVITATIONS}
                    />
                  }
                />

                <Route
                  path="/settings/invitations"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => (
                        <SettingLayoutNew>
                          <SettingInvitations />
                        </SettingLayoutNew>
                      )}
                      permission={RBACPermissions.SETTING_INVITATIONS}
                    />
                  }
                />

                {
                  //TODO: Put on visible this after demo.
                }
                {/*<Route            
                  path="/analytics"
                  element={
                    <RoleAuthorizationAdmin
                      render={() => <Analytics />}
                      permission={RBACPermissions.TENANT_ANALYTICS}
                    />
                  }
                />*/}
              </RoutesComponent>
            </Layout>
          </Session>
        }
      />
    </RoutesComponent>
  );
};
