import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Chip,
  Hidden,
  Stack,
  Divider,
} from '@mui/material';
import {
  changeDashboardDateFilterStoreAction,
  fetchUsersCountStoreAction,
} from '../dashboard-actions';
import { DashboardRegisterUserItem } from './DashboardRegisterUserItem';
import { DashboardUserByMonthBar } from './DashboardUserByMonthBar';
import { DashboardUserProviderBar } from './DashboardUserProviderBar';
import { DashboardWinnerNoteContainer } from './DashboardWinnerNoteContainer';
import { DashboardRegisterUsersTable } from './DashboardRegisterUsersTable';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../dashboard-events';
import { useAuth } from '../../auth/auth-hook';
import { fetchUserByMonth } from '../../user/user-actions';
import { DateRangePicker } from '../../../shared/components/DateRangePicker';
import { formatterDateFilter, sanitizeCustomDate } from '../../../shared/utils';
import { DateFilter } from '../../../shared/constants';
import { DashboardTotalUserItem } from './DashboardTotalUserItem';
import { DashboardGeneralDateText } from './DashboardGeneralDateText';
import moment from 'moment';

export const DashboardGeneralTab: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { tenant } = useAuth();
  const [callFetchUser] = useCallAction(fetchUserByMonth, {
    onCompleted: (data) => {
      fetchUsersCountStoreAction(false, data);
    },
    onError: (data) => {
      fetchUsersCountStoreAction(false, []);

    },
  });

  useEffect(() => {
    if (tenant) {
      callFetchUser({
        tenant,
        from,
        to,
        filter: filter,
      });
    }
  }, [tenant, callFetchUser, from, to, filter]);

  return (
    <Grid container justifyContent='center'>
      <Grid xs={12} item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography>Informe general de registro de usuarios</Typography>
              <Box
                display='flex'
                alignItems='center'
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    justifyContent: 'flex-end',
                  },
                })}
              >
                <DashboardGeneralDateText />
                <DateRangePicker
                  onChange={(range) => {

                    console.log({
                      ranges: {
                        start: moment(range.startDate).utc(true).format(),
                      },
                    });

                    const dateFilter = sanitizeCustomDate(
                      range.key as DateFilter,
                      {
                        startDate: range.startDate,
                        endDate: range.endDate,
                      },
                    );

                    changeDashboardDateFilterStoreAction(
                      moment(range.startDate).startOf('day').utc(true).format(),
                      moment(range.endDate).endOf('day').utc(true).format(),
                      dateFilter,
                    );
                    fetchUsersCountStoreAction(true, []);
                  }}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <DashboardTotalUserItem />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardRegisterUserItem />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardUserByMonthBar />
          </Grid>
          <Grid item xs={12} md={6}>
            <DashboardUserProviderBar />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ margin: '10px 0px !important' }} />
          </Grid>
          <Grid item xs={12}>
            <DashboardWinnerNoteContainer />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: '20px', marginBottom: '0px' }} />
          </Grid>
          <Grid item xs={12}>
            <DashboardRegisterUsersTable />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
