import * as yup from 'yup';

export const planDetailsSchema = yup.object().shape({
  title: yup
    .string()
    .required('El título es requerido')
    .min(3, 'El título debe tener al menos 3 caracteres')
    .max(100, 'El título no puede tener más de 100 caracteres'),

  sub_title: yup
    .string()
    .required('El subtítulo es requerido')
    .min(3, 'El subtítulo debe tener al menos 3 caracteres')
    .max(100, 'El subtítulo no puede tener más de 100 caracteres'),

  description: yup
    .string()
    .required('La descripción es requerida')
    .min(10, 'La descripción debe tener al menos 10 caracteres')
    .max(500, 'La descripción no puede tener más de 500 caracteres'),

  price: yup
    .number()
    .required('El precio es requerido')
    .min(0, 'El precio debe ser un valor positivo')
    .typeError('El precio debe ser un número válido'),

  status: yup
    .string()
    .required('El estado es requerido')
    .oneOf(['ACTIVE', 'INACTIVE'], 'El estado debe ser "Activo" o "Inactivo"'),

  frequency: yup
    .string()
    .required('La frecuencia es requerida')
    .oneOf(['YEAR', 'MONTH'], 'La frecuencia debe ser "Anual" o "Mensual"'),

  is_premium: yup
    .boolean()
    .required('Se requiere especificar si es un plan premium'),

  helper_text: yup
    .string()
    .max(200, 'El texto de ayuda no puede tener más de 200 caracteres')
    .nullable(),
});

export const createCouponSchema = yup.object().shape({
  discount: yup
    .number()
    .label('Descuento')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'El descuento no puede ser negativo')
    .required('El descuento es requerido')
    .test(
      'at-least-one-discount',
      'Debe ingresar un porcentaje o un monto de descuento valido.',
      (val, ctx) => {
        const value = val ?? 0;
        const ctxValue = ctx?.parent?.discountType ?? 'percent';

        if (value < 0) {
          return false;
        }

        if (ctxValue === 'percent' && value > 100) {
          return false;
        }

        return true;
      },
    ),
  discountType: yup
    .string()
    .label('Tipo de descuento')
    .oneOf(
      ['percent', 'amount'],
      'El tipo de descuento debe ser "Porcentaje" o "Monto fijo"',
    )
    .required('El tipo de descuento es requerido'),
  maxRedemptions: yup
    .number()
    .label('Máximo de redenciones')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(0, 'Debe permitir al menos una redención')
    .nullable(),
  expirationDate: yup.string().nullable(),
  durationInMonths: yup
    .number()
    .label('Duración en meses')
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .min(1, 'La duración en meses debe ser mayor a 0')
    .nullable(),
  isPublic: yup
    .boolean()
    .required('Se requiere especificar si el cupón es público'),
  isForSharing: yup
    .boolean()
    .required('Se requiere especificar si el cupón es para compartir'),
});
