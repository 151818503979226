export enum DashboardEvents {

  wallCrashesPortal = 'wall_crashes_portal',
  wallCrashesContent = 'wall_crashes_content',


  loginClicked = 'login_clicked',
  registerClicked = 'register_clicked',
  startSession = 'start_session',
  swgStartSession = 'swg_start_session',
  facebookClicked = 'facebook_clicked',


  startSessionGoogle = 'start_session_google',
  registerGoogle = 'register_google',
  connectToGoogle = 'connect_to_google',
  disconnectToGoogle = 'disconnect_to_google',

  startSessionApple = 'start_session_apple',
  registerApple = 'register_apple',
  connectToApple = 'connect_to_apple',
  disconnectToApple = 'disconnect_to_apple',


  startSessionFacebook = 'start_session_facebook',
  registerFacebook = 'register_facebook',
  connectToFacebook = 'connect_to_facebook',
  disconnectToFacebook = 'disconnect_to_facebook',

  authClicked = 'auth_clicked',
  wallCrashesSubscription = 'wall_crashes_subscription',
  wallCrashesFreeSubscription = 'wall_crashes_free_subscription',
  wallCrashesRegister = 'wall_crashes_register',
  swgRegisterUser = 'swg_register_user',
  pageVisit = 'page_visit',
  gmailClicked = 'gmail_clicked',
  registerUser = 'register_user',
  wallCrashesModal = 'wall_crashes_modal',
  analyticsAuthenticate = 'analytics_authenticate',
  appleClicked = 'apple_clicked',
  skipSwg = 'skip_swg',


  mobileWallCrashesFreeSubscription = 'mobile_wall_crashes_free_subscription',
  mobileWallCrashesSubscription = 'mobile_wall_crashes_subscription',
  mobileWallCrashesRegister = 'mobile_wall_crashes_register',

  mobileFacebookClicked = 'mobile_facebook_clicked',
  mobileGmailClicked = 'mobile_gmail_clicked',
}

export type WinnerNote = {
  count: number;
  url: string;
  id: string;
};

export type CountData = { count: number; date: string };


export type GenerateUserReport = {
  created_at?:string;
  date?:string;
  name?:string;
  email?:string;
  provider?:string;
  provider_id?:string;
  url_register?:string;
  gender?:string;
  sex?:string;
}