import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  changeDashboardDateFilterStore,
  changeDateFilterStore,
  fetchUsersCountStore,
  winnerNoteErrorEvents,
  winnerNoteErrorPaginateEvents,
  winnerNoteEvents,
  winnerNotePaginateEvents,
  changeGoogleDateFilterStore,
  selectedSectionStore,
  fetchGenerateUserReportEvent,
  fetchGenerateUserReportErroEvent,
  fetchGenerateWinnerReportEvent,
  fetchGenerateWinnerReportErroEvent,
  changeWinnerNotesOrderStore,
  fetchCouponReportByFilterEvent,
  fetchCouponReportByFilterEventError,
  fetchCouponRedemptionsListEvent,
  fetchCouponRedemptionsListEventError,
} from './dashboard-events';
import { DateFilter } from '../../shared/constants';
import axios from 'axios';
import { Tenant } from '../../shared/types/generated';
import { CountData, GenerateUserReport, WinnerNote } from './dashboard-types';
import { GlobalUser } from '../user/user-types';

export const changeDateFilterStoreAction = createStoreAction(
  changeDateFilterStore,
  (prev, date: DateFilter) => ({
    ...prev,
    date,
  }),
);

export const changeDashboardDateFilterStoreAction = createStoreAction(
  changeDashboardDateFilterStore,
  (prev, from: string, to: string, filter: DateFilter) => ({
    ...prev,
    from,
    to,
    filter,
  }),
);

export const winnerNotes = createAction(
  winnerNoteEvents,
  winnerNoteErrorEvents,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    order: 'asc' | 'desc';
  }) => {
    const { tenant, to, from, order } = data;

    const response = await axios.get<{
      data: WinnerNote[];
      total: number;
    }>(`${tenant.webhook}/api/user/winner-notes`, {
      params: {
        to,
        from,
        order,
      },
    });

    return response.data;
  },
);

export const winnerNotesPaginate = createAction(
  winnerNotePaginateEvents,
  winnerNoteErrorPaginateEvents,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    page: number;
    pageSize: number;
    order: 'asc' | 'desc';
  }) => {
    const { tenant, to, from, page, order, pageSize } = data;

    const response = await axios.get<{
      data: WinnerNote[];
      total: number;
    }>(`${tenant.webhook}/api/user/winner-notes`, {
      params: {
        to,
        from,
        page,
        order,
        pageSize,
      },
    });

    return response.data;
  },
);

export const fetchUsersCountStoreAction = createStoreAction(
  fetchUsersCountStore,
  (prev, loading: boolean, users: CountData[]) => ({
    ...prev,
    loading,
    users,
  }),
);

export const changeGoogleDateFilterStoreAction = createStoreAction(
  changeGoogleDateFilterStore,
  (prev, from: string, to: string, filter: DateFilter) => ({
    ...prev,
    from,
    to,
    filter,
  }),
);

export const selectedSectionStoreAction = createStoreAction(
  selectedSectionStore,
  (prev, section: string) => ({
    ...prev,
    section,
  }),
);

export const fetchGenerateUserReport = createAction(
  fetchGenerateUserReportEvent,
  fetchGenerateUserReportErroEvent,
  async (data: {
    tenant: Tenant | null | undefined;
    from: string;
    to: string;
  }) => {
    const { tenant, to, from } = data;

    const response = await axios.get<GenerateUserReport[]>(
      `${tenant?.webhook}/api/user/generate-user-report`,
      {
        params: {
          to,
          from,
        },
      },
    );

    return {
      report: response.data,
    };
  },
);

export const fetchGenerateWinnerReport = createAction(
  fetchGenerateWinnerReportEvent,
  fetchGenerateWinnerReportErroEvent,
  async (data: {
    tenant: Tenant | null | undefined;
    from: string;
    to: string;
  }) => {
    const { tenant, to, from } = data;

    const response = await axios.get<WinnerNote[]>(
      `${tenant?.webhook}/api/user/generate-winner-note-report`,
      {
        params: {
          to,
          from,
        },
      },
    );

    return {
      report: response.data,
    };
  },
);

export const changeWinnerNotesOrderStoreAction = createStoreAction(
  changeWinnerNotesOrderStore,
  (prev, order: 'asc' | 'desc') => ({
    ...prev,
    order,
  }),
);

export const fetchCouponReportByFilter = createAction(
  fetchCouponReportByFilterEvent,
  fetchCouponReportByFilterEventError,
  async (data: { tenant: Tenant; from: string; to: string }) => {
    const { tenant, from, to } = data;
    const url = `${tenant.webhook}/api/coupons/group-by-type`;

    try {
      const response = await axios.get(url, {
        timeout: 60000,
        params: {
          to,
          from,
        },
      });

      return response.data;
    } catch (error: any) {
      const msg =
        error?.response?.data?.message ?? 'Error al obtener los cupones';
      throw new Error(msg);
    }
  },
);

export const fetchCouponRedemptionsList = createAction(
  fetchCouponRedemptionsListEvent,
  fetchCouponRedemptionsListEventError,
  async (data: {
    tenant: Tenant;
    from: string;
    to: string;
    page: number;
    pageSize: number;
  }) => {
    const { tenant, from, to, page, pageSize } = data;
    const url = `${tenant.webhook}/api/coupons/redemptions`;

    try {
      const response = await axios.get(url, {
        timeout: 60000,
        params: {
          to,
          from,
          page,
          pageSize,
        },
      });

      return response.data;
    } catch (error: any) {
      const msg =
        error?.response?.data?.message ?? 'Error al obtener los cupones';
      throw new Error(msg);
    }
  },
);
