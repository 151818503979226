import React from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { InputDefault } from '../../../shared/components/inputs/InputDefault';
import { SelectDefault } from '../../../shared/components/inputs/SelectDefault';
import { Loading } from '../../../shared/components/Loading';
import { handleFormErrors } from '../../../shared/utils/errors';
import { planDetailsSchema } from '../tenant-subs-plan-validator';
import { TenantSubscriptionPlan } from '../tenant-subs-plan-types';

export const TenantSubsPlanDetailsForm: React.FC<{
  data: TenantSubscriptionPlan | null;
  loading: boolean;
}> = ({ data, loading }) => {
  const isEditable = false;

  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(planDetailsSchema, { abortEarly: false }),
    defaultValues: {
      title: data?.title ?? '',
      sub_title: data?.sub_title ?? '',
      description: data?.description ?? '',
      price: data?.price ?? 0,
      status: data?.status ?? '',
      frequency: data?.frequency ?? '',
      is_premium: data?.is_premium ?? false,
      helper_text: data?.helper_text ?? '',
    },
  });

  const onFormSubmit = handleSubmit(
    async (dataForm) => {
      /**
       * Here you can call the action to update the plan
       */
      console.log(dataForm);
    },
    (error) => {
      handleFormErrors(error);
    },
  );

  return (
    <Box component="form" onSubmit={onFormSubmit}>
      {loading || !data ? (
        <Loading height="400px" />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name={'title'}
              control={control}
              render={({ field }) => (
                <InputDefault
                  variant="outlined"
                  labelName="Título"
                  inputProps={{ readOnly: !isEditable }}
                  helperText={formState.errors.title?.message}
                  error={!!formState.errors.title?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={'sub_title'}
              control={control}
              render={({ field }) => (
                <InputDefault
                  variant="outlined"
                  labelName="Subtítulo"
                  inputProps={{ readOnly: !isEditable }}
                  helperText={formState.errors.sub_title?.message}
                  error={!!formState.errors.sub_title?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={'description'}
              control={control}
              render={({ field }) => (
                <InputDefault
                  variant="outlined"
                  labelName="Descripción"
                  inputProps={{ readOnly: !isEditable }}
                  helperText={formState.errors.description?.message}
                  error={!!formState.errors.description?.message}
                  multiline
                  rows={4}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={'price'}
              control={control}
              render={({ field }) => (
                <InputDefault
                  variant="outlined"
                  labelName="Precio"
                  type="number"
                  inputProps={{ readOnly: !isEditable }}
                  helperText={formState.errors.price?.message}
                  error={!!formState.errors.price?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={'status'}
              control={control}
              render={({ field }) => (
                <SelectDefault
                  variant="outlined"
                  labelName="Estado"
                  inputProps={{ readOnly: !isEditable }}
                  helperText={formState.errors.status?.message}
                  error={!!formState.errors.status?.message}
                  options={[
                    {
                      value: 'ACTIVE',
                      label: 'ACTIVE',
                    },
                    {
                      value: 'INACTIVE',
                      label: 'INACTIVE',
                    },
                  ]}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={'frequency'}
              control={control}
              render={({ field }) => (
                <SelectDefault
                  variant="outlined"
                  labelName="Frecuencia"
                  inputProps={{ readOnly: !isEditable }}
                  helperText={formState.errors.frequency?.message}
                  error={!!formState.errors.frequency?.message}
                  options={[
                    { value: 'YEAR', label: 'Anual' },
                    { value: 'MONTH', label: 'Mensual' },
                  ]}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name={'is_premium'}
              control={control}
              render={({ field }) => (
                <SelectDefault
                  variant="outlined"
                  labelName="Es premium"
                  inputProps={{ readOnly: !isEditable }}
                  helperText={formState.errors.is_premium?.message}
                  error={!!formState.errors.is_premium?.message}
                  options={[
                    {
                      value: 'true',
                      label: 'Premium',
                    },
                    {
                      value: 'false',
                      label: 'No premium',
                    },
                  ]}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={'helper_text'}
              control={control}
              render={({ field }) => (
                <InputDefault
                  variant="outlined"
                  labelName="Texto de ayuda"
                  inputProps={{ readOnly: !isEditable }}
                  helperText={formState.errors.helper_text?.message}
                  error={!!formState.errors.helper_text?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          {isEditable && (
            <Grid item xs={12}>
              <Box textAlign="end">
                <ButtonDefault
                  isLoading={false}
                  type="submit"
                  style={{
                    width: 190,
                    height: 40,
                    textTransform: 'inherit',
                    fontWeight: 700,
                  }}>
                  ACTUALIZAR PLAN
                </ButtonDefault>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};
